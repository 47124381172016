<template>
    <div class="font-bold"> 
        <span class="text-gray-500">
            ({{parseFloat(item.precipitation).toFixed(2)}}) 
        </span>
        
        Berdasarkan hari ini jam {{parseDate(item.last_update,'HH.mm')}}
    </div>
</template>

<script>
import globalMixin from '../../mixin/global'
export default {
    props:['item'],
    mixins:[globalMixin]
}
</script>
