<template>
    <div>
        

        <div class="rounded w-full shadow bg-white z-30" v-for="item in devices" :key="item.id">
            <div class="py-2 px-4 rounded rounded-b-none bg-blue-400 text-white col-span-12 mt-4" v-if="item.type == 'level'">Ketinggian Air {{item.name}}</div>
            <div class="py-2 px-4 rounded rounded-b-none bg-green-400 text-white col-span-12 mt-4" v-if="item.type == 'WQ'">pH Air {{item.name}}</div>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-8 border border-gray-200">
                    <div :id="'chart' +item.id" ></div>
                </div>

                <div class="col-span-12 md:col-span-4 border border-gray-200 p-4 flex flex-wrap content-center justify-center" v-if="item.type == 'level'">
                    <div class="relative bottom-0 w-full flex flex-wrap content-center justify-center">
                        <div class="">
                                <div style="height:65px" class="bg-red-500 text-white font-bold p-2 w-12">
                                400
                            </div>
                            <div style="height:60px" class="bg-yellow-500 text-white font-bold p-2 w-12">
                                200
                            </div>
                            <div style="height:25px" class="bg-blue-700 text-white font-bold px-2 w-12">
                                120
                            </div>
                            <div style="height:60px" class="bg-green-500 text-white font-bold p-2 w-12">
                                100
                            </div>
                        </div>
                        <div class="absolute bottom-0 w-full text-right text-gray-700 font-bold"  :style="`height:68px;`">
                            120 cm
                        </div>
                        <div class="absolute bottom-0 w-full text-right text-gray-700 font-bold"  :style="`height:93px;`">
                            170 cm
                        </div>
                        <div class="absolute bottom-0 w-full text-right text-gray-700 font-bold"  :style="`height:153px;`">
                            270 cm
                        </div>
                        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:${parseFloat(item.real_ketinggian / 2) + 23}px;`">
                            {{parseFloat(item.real_ketinggian).toFixed(2)}} cm
                            <div class="bg-gradient-to-b from-blue-500 text-left" style="height:100%"></div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-4 border border-gray-200 p-4 flex flex-wrap content-center justify-center" v-if="item.type == 'WQ'">
                    <phComponent :currentDevice="item" />
                   
                </div>


            </div>
        </div>
    </div>
    
</template>
<script>
var chartSignal,multiChart
import ApexCharts from 'apexcharts'
import globalMixin from '../../mixin/global'
import {mapGetters} from 'vuex'
import phComponent from './phComponent.vue'
export default {
    mixins:[globalMixin],
    data: () => ({
        update_data:false,
        last_heigh:0,
        chart:[],
        options:
            {
            series: [],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 300,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
              
            },
        
            yaxis: {
               
               labels:{
                    formatter: (value) => { return value.toFixed(0) },
                },
                title: {
                    text: 'Ketinggian Air [cm]'
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
           tooltip: {
                marker: {
                    show: false,
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `<div class="p-4">
                    <span class="text-green-400 font-bold"> h :${series[seriesIndex][dataPointIndex]} cm </span>
                      
                      <br>
                      <span class="mt-2 text-blue-400 font-bold">Elevasi Muka Air : ${w.config.series[seriesIndex].data[dataPointIndex].h ? w.config.series[seriesIndex].data[dataPointIndex].h : ''} m</span>
                    
                      <br>
                      <span class="mt-2 text-yellow-400 font-bold"> Q :${w.config.series[seriesIndex].data[dataPointIndex].debit ? w.config.series[seriesIndex].data[dataPointIndex].debit : ''} m3/dt </span>
                      </div>`
                },
                 x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
        devices:[],
        chart_device:[],
        data_series:[]
    }),
    methods:{
        async getData() {
            this.data_series = []
            await this.axios.get('v1/device_data/detail/11',this.config)
            .then(async (ress) => {
                await ress.data.data.forEach( x => {
                let label = x.last_update
                // this.dataLevel.push({
                //     x: new Date(label).getTime(),
                //     // x: parseInt(x.time),
                //     y: parseFloat(x.level).toFixed(2)
                // })
                this.data_series.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    h: parseFloat(x.elevasi_muka_air).toFixed(2),
                        y: parseFloat(x.real_ketinggian * 100).toFixed(2),
                        debit: parseFloat(x.debit).toFixed(4)

                })
                this.last_heigh =  parseFloat(x.real_ketinggian * 100).toFixed(2)
              
                   
            })
                this.$set(this.options,'series',[{name:'Ketinggian Air [cm]',data:this.data_series}])

                if (this.update_data == false) {
               
                   chartSignal = new ApexCharts(document.querySelector("#chart-multi"), this.options);
                
                    await chartSignal.render();
                }
              
            })


        },
        async getDataV2() {
            multiChart = []
            let customer_id = this.customer ? this.customer.id : ''
            await this.axios.get('v2/dashboard/monitor?customer_id=' + customer_id,this.config)
            .then(async (ress) => {
                this.devices = ress.data.data
                await this.generateChart()
                this.chart_device.forEach(async x => {
                    multiChart.push({...x,chart:await new ApexCharts(document.querySelector("#chart" + x.id), x.option)})
                })

            })

            multiChart.forEach(x => {
                x.chart.render()
            })

        },
        async generateChart() {
            await this.devices.forEach(async x => {
            let option = {
                ...this.options
            }
            if (x.type == 'WQ') {
                this.$set(option,'series',[{name:'pH Air',data:x.dataPh}])
                this.$set(option,'tooltip',{ x: {
                    format: 'y-MM-dd HH:mm:ss',
                }})
                this.$set(option,'yaxis',{
                min: 0.00,
                max: parseInt(x.pH) + 4,
                labels:{
                    formatter: (value) => { return value.toFixed(2) },
                },
                title: {
                    text: 'pH Air'
                },
                })
            } else if(x.type == 'level') {
                this.$set(option,'series',[{name:'Ketinggian Air [cm]',data:x.dataElevasi}])

            }
            await this.chart_device.push({...x,option:option})
        })
        }
    },
    async created() {
        await this.getDataV2()
        // this.generateChart()
        

    },
    async mounted() {
        this.sockets.subscribe('updatedatachart', async (data) => {
            let findChart = await multiChart.findIndex(x => x.id === data.id)
            if (findChart >= 0 ) {
                let dataPh = this.devices[findChart].dataPh
                let dataElevasi = this.devices[findChart].dataElevasi
                dataElevasi.push({
                    x: new Date(data.last_update).getTime(),
                    h: parseFloat(data.elevasi_muka_air).toFixed(2),
                        y: parseFloat(data.real_ketinggian * 100).toFixed(2),
                        debit: parseFloat(data.debit).toFixed(4)
                })
                dataPh.push({
                    x: new Date(data.last_update).getTime(),
                    y: parseFloat(data.pH).toFixed(2),
                })
                if (multiChart[findChart].type == 'level') {
                    this.devices[findChart].real_ketinggian = data.real_ketinggian * 100
                    multiChart[findChart].chart.updateSeries([{name:'Ketinggian Air [cm]',data:dataElevasi}])
                } else if(multiChart[findChart].type == 'WQ') {
                     this.devices[findChart].pH = parseFloat(data.pH).toFixed(2)
                    multiChart[findChart].chart.updateSeries([{name:'pH Air',data:dataPh}])
                }
            }
         
            this.update_data = true
        });

    },
    computed:{
        ...mapGetters({
            customer:'device/customer',
        })
    },
    components:{
        phComponent
    },  
    beforeDestroy() {
        this.sockets.unsubscribe('updatedatachart');
    }
}
</script>