<template>
  <div class="h-screen w-screen flex flex-wrap content-center justify-center ">
    <div class="w-full h-full">
        <div class="mx-auto rounded grid grid grid-cols-12 items-center">
            <div class="col-span-6 h-screen flex items-center justify-center" style="background-color:#FCFCFC !important">
              <img src="/img/login.svg" class="" />
            </div>
            <div class="col-span-12 md:col-span-6 text-xs">
                <div class="text-left p-24" >
                  <div class="mb-8" v-if="!success">
                      <p class="font-bold text-2xl mb-2">Reset Password</p>
                      <div class="w-96">
                        <span class="text-sm text-gray-400 mt-2">Masukkan email atau username kamu dan kami akan mengirimkan link untuk mengatur ulang password kamu </span>

                      </div>

                  </div>
                  <div class="mb-8" v-else >
                      <i class="fas fa-check-circle text-3xl mb-2"></i>
                      <p class="font-bold text-2xl mb-2">Email berhasil terkirim</p>
                      <div class="w-96">
                        <span class="text-sm text-gray-400 mt-2">
                          Cek email kamu dan buka link yang sudah kami kirimkan untuk mengatur ulang password
                        </span>

                      </div>

                  </div>

                <div v-if="error" class="bg-red-50 border-red-500 text-red-700 text-red-500 p-4 mb-4 mt-4">
                  {{error}}
                </div>

              
                <ValidationObserver v-slot="{ invalid}" ref="form" v-if="!success">
                <form @submit.prevent="login()" >

                <ValidationProvider rules="required" v-slot="{ errors }" name="Email" vid="email">
                    <div :class="`${errors[0] ? '' : ' mb-4'}`">
                      <defaultInput v-model="email" :field="email" placeholder="Email Atau Username" type="text" />
                      <div class="mb-4">
                          <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                      </div>
                    </div>
                </ValidationProvider>

                <button
                    :disabled="invalid"
                    type="submit"
                    :class="`w-full text-center py-4 mt-4 rounded-lg
                    text-white
                    bg-blue-500
                    disabled:opacity-50
                    focus:outline-none my-1`"


                >Kirim link ke email</button>

                <p :class="`w-full text-center 
                    text-blue-500
                    cursor-pointer font-bold mt-4`"  @click="$router.push('/login')">Kembali ke halaman masuk</p>

                </form>
              </ValidationObserver>
                </div>
            </div>
            
        </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import {mapActions} from 'vuex'
import store from '../../store/index.js'
import defaultInput from '../../components/formcomponent/defaultinput.vue'

export default {
    data() {
        return {
            email:'',
            password:'',
            error:'',
            success:'',
        }
    },
    components:{
        ValidationProvider,ValidationObserver,defaultInput
    },
    methods: {
      ...mapActions({
        setUser : 'auth/setUser'
      }),
      async login() {
        this.error = ''
        this.success = ''
        await this.axios.post('v1/auth/reset-password',{email:this.email})
        .then((ress) => {
           this.success = 'Silakan Cek Email Anda'
        }).catch((err) => {
          this.error = 'Email atau Username salah'
        })
      }
    },

    async beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    let token = localStorage.getItem('token')

    if (token) {
      
      await axios.get('v1/auth/me',{
        headers:{
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(async (ress) => {
          const new_user = ress.data
          await store.dispatch('auth/setUser', new_user)
          await localStorage.setItem('user',JSON.stringify(ress.data.user))
          await localStorage.setItem('token',ress.data.token)
          if(store.getters['beforeUrl']) {
          next(store.getters['beforeUrl']) 

          } else {
          next('/dashboard')

          }
        }
        )
        .catch((err) => {
          localStorage.removeItem('user')
          localStorage.removeItem('token')
          next()
        })
    }
    next()
  }

}
</script>
