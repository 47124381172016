export default {
    data: () => ({
        optionsBar:
            {
            series: [{
                name:"Ketinggian Air",
                data: []
            }, {
                name:"Elevasi Muka Air",
                data: []
            },
            {
                name:"Debiet",
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[2001, 2002, 2003, 2004, 2005, 2006, 2007],
            chart: {
                type: 'bar',
                // stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    show:false,
                    autoSelected: 'zoom'
                },
                events: {
                 
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                    borderRadius:10,
                    columnWidth: '20%',

                }
              },
            dataLabels: {
                enabled: true,
              
                dropShadow: {
                    enabled: true,
                    left: 2,
                    top: 2,
                    opacity: 0.1
                },
                offsetY: -20,
                  style: {
                    fontSize: '10px',
                    colors: ['#3B82F6', '#34D399', '#A78BFA'],

                  },
                  
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            colors: ['#3B82F6', '#34D399', '#A78BFA'],

            yaxis: {
                labels:{
                    formatter: (value) => { return value },
                },
            },
            tooltip: {
               
            },
           
            
            stroke: {
                show: true,
                curve: 'stepline',
                lineCap: 'square',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}