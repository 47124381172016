<template>
     <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="p-6 text-white font-bold">
            <div class="">
                <p class="text-sm text-black ">Kadar Asam</p>
                <text-ph :item="currentDevice" />
            </div>
            <div class="">
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 14 ? 'color:#312E81' : ''}`">14</div>
                    <div class="h-5 w-full hover:borde-2 hover:border-gray-500" style="background-color:#312E81" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Alkaline">
                    </div>
                </div>

                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 13 && currentDevice.pH < 14 ? 'color:#1E3A8A' : ''}`">13</div>
                    <div class="h-5 w-full " style="background-color:#1E3A8A" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Alkaline">
                    </div>
                </div>
                 <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 12 && currentDevice.pH < 13 ? 'color:#134E4A' : ''}`">12</div>
                    <div class="h-5 w-full" style="background-color:#134E4A" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Alkaline">
                    </div>
                </div>
                 <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 11 && currentDevice.pH < 12 ? 'color:#065F46' : ''}`">11</div>
                    <div class="h-5 w-full" style="background-color:#065F46" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Alkaline">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 10 && currentDevice.pH < 11 ? 'color:#047857' : ''}`">10</div>
                   <div class="h-5 w-full " style="background-color:#047857" data-bs-toggle="tooltip" data-bs-placement="top" title="Alkaline">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 9 && currentDevice.pH < 10 ? 'color:#059669' : ''}`">9</div>
                    <div class="h-5 w-full" style="background-color:#059669" data-bs-toggle="tooltip" data-bs-placement="top" title="Slightly Alkaline">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 8 && currentDevice.pH < 9 ? 'color:#10B981' : ''}`">8</div>
                    <div class="h-5 w-full" style="background-color:#10B981" data-bs-toggle="tooltip" data-bs-placement="top" title="Slightly Alkaline">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 7 && currentDevice.pH < 8 ? 'color:#84CC16' : ''}`">7</div>
                    <div class="h-5 w-full" style="background-color:#84CC16" data-bs-toggle="tooltip" data-bs-placement="top" title="Netral">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 6 && currentDevice.pH < 7 ? 'color:#A3E635' : ''}`">6</div>
                    <div class="h-5 w-full" style="background-color:#A3E635" data-bs-toggle="tooltip" data-bs-placement="top" title="Slightly Acidid">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 5 && currentDevice.pH < 6 ? 'color:#FACC15' : ''}`">5</div>
                    <div class="h-5 w-full " style="background-color:#FACC15" data-bs-toggle="tooltip" data-bs-placement="top" title="Slightly Acidid">
                    </div>
                </div>

                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 4 && currentDevice.pH < 5 ? 'color:#EAB308' : ''}`">4</div>
                    <div class="h-5 w-full" style="background-color:#EAB308" data-bs-toggle="tooltip" data-bs-placement="top" title="Acidid">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right " :style="`${currentDevice.pH >= 3 && currentDevice.pH < 4 ? 'color:#F97316' : ''}`">3</div>
                    <div class="h-5 w-full " style="background-color:#F97316" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Acidid">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH >= 2 && currentDevice.pH < 3 ? 'color:#EA580C' : ''}`">2</div>
                    <div class="h-5 w-full " style="background-color:#EA580C" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Acidid">
                    </div>
                </div>
                <div class="flex items-center pr-3">
                    <div class="text-xs text-gray-400 w-4 mr-3 text-right" :style="`${currentDevice.pH <= 1? 'color:#C2410C' : ''}`">1</div>
                    <div class="h-5 w-full " style="background-color:#C2410C" data-bs-toggle="tooltip" data-bs-placement="top" title="Very Acidid">
                    </div>

                </div>
                 
                 
                  
                
            </div>
            
        </div>
    </div>
</template>

<script>
import textPh from './textPh.vue'
export default {
    props:['currentDevice'],
    components:{
        textPh
    },
}
</script>