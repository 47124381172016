<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                     <div class="flex flex-wrap items-center content-between justify-between">
                        <span class="font-semibold text-base">Log Aktifitas</span>
                        <div class="flex">
                            <div  class="text-xs relative" > 
                                <div class="absolute top-2.5 left-2.5">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                    
                                </div>

                                <input
                                v-model="keyword"

                                type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                ref="keyword"
                                id="keyword"
                                @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                            </div>
                         
                        </div>
                    
                    </div>
                      <div class="mt-4 w-full overflow-x-auto">
                        <table class="w-full">
                            <thead>
                                <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                    <td class="py-4">Nama</td>
                                    <td>Description</td>
                                    <td>IP</td>
                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                    
                                    <td class="py-4">
                                        <span>{{item.User ? item.User.name : ''}}</span>
                                    </td>
                                    <td>
                                        <span>{{item.description}}</span>
                                    </td>
                                       <td>
                                        <span>{{item.ipv4}}</span>
                                    </td>
                                   
                                </tr>
                            </tbody>
                        </table>

                        <div class="flex justify-end items-center mt-4">

                            <div class="flex items-center ">
                                <div class="font-medium">
                                <span>{{startNumber}}-{{endNumber}} of {{totalRows}}</span> 
                                </div>
                                <div class="flex">
                                    <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"  @click="previousPage()"></unicon>
                                    <unicon name="angle-right" class="cursor-pointer" :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
export default {
    data: () => ({
            data: [],
        totalRows:100,
        perPage:10,
        limit:8,
        startNumber:1,
        endNumber:8,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        getData(){
            this.axios.get('v1/logactivities?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}`,this.config)
            .then((ress) => {
                this.data = ress.data.rows
                this.totalRows = ress.data.count
                 if (this.totalRows <= this.limit) {
                    this.endNumber = this.totalRows
                } else if(this.currentPage > 1 ) {
                    let page = this.currentPage - 1
                    this.startNumber = page * this.limit + 1
                    this.endNumber = this.currentPage * this.limit
                    if (this.totalRows < this.endNumber) {
                        this.endNumber = this.totalRows
                    }

                } else {
                    this.startNumber = 1
                    this.endNumber = this.limit
                }
                                this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1 ) * this.limit + 1
            })
            .catch((err) => {
                this.errorResponse(err)

            })
        },
    
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    created() {
        this.getData()
    },
    components:{
        
    }
}
</script>