<template>
     <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="" >
            <div class="flex justify-center items-centers w-full">
                <img src="/img/Rainy.svg" class="w-28 mt-4" v-if="currentDevice.precipitation > 0" >
                <img src="/img/Sunny.svg" class="w-28 mt-4" v-if="currentDevice.precipitation <= 0" >
            </div>
            

            <div class="mt-2">
                <p class="text-xl font-bold">{{parseFloat(currentDevice.airTemperature).toFixed(0)}} <sup>o</sup> <span class="font-semibold">C</span></p>
                <p class="text-gray-400 font-lg">
                    {{currentDevice.precipitation > 0 ? 'Hujan' : 'Cerah'}}
                </p>
            </div>
            <div class="mt-4">
                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="temperature-half" width="19px" class="mr-1" fill="#94A3B8" ></unicon>
                            <p class="text-gray-400">Tekanan</p>

                        </div>
                        <p>{{parseFloat(currentDevice.atmosphericPressure).toFixed(3)}} kPa</p>
                    </div>
                    
                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="wind" width="19px" class="mr-1" fill="#94A3B8" ></unicon>
                            <p class="text-gray-400">Kecepatan Angin</p>

                        </div>
                        <p>{{parseFloat(currentDevice.windSpeed).toFixed(0)}} km/h</p>
                    </div>
                    
                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="tear" width="19px" class="mr-1" fill="#94A3B8" ></unicon>
                            <p class="text-gray-400">Kelembapan</p>

                        </div>
                        <p>{{parseFloat(currentDevice.relativeHumidity).toFixed(2)}}</p>
                    </div>
                    
                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                         <div class="flex items-center">
                            <unicon name="raindrops" width="19px" class="mr-1" fill="#94A3B8" ></unicon>
                            <p class="text-gray-400">Curah Hujan</p>
                        </div>
                        <p>{{parseFloat(currentDevice.precipitation).toFixed(2)}} mm</p>
                    </div>
                    
                </div>
                
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['currentDevice'],
    components:{
    },
}
</script>