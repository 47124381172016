export default {
    data: () => ({
        optionsChart:
        {
            series: [],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 250,
                zoom: {
                    type: 'x',
                    enabled: false,
                    autoScaleYaxis: false
                },
                toolbar: {
                    show:false,
                    autoSelected: 'zoom'
                }
            },
            colors:['#3B82F6'],
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
                // type: 'gradient',
                // gradient: {
                //     shadeIntensity: 1,
                //     inverseColors: false,
                //     opacityFrom: 0.9,
                //     opacityTo: 0,
                //     stops: [0, 90, 100]
                // },
            },
        
            yaxis: {
               
               labels:{
                    formatter: (value) => { return value.toFixed(0) },
                },
                title: {
                    text: undefined
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
           tooltip: {
                marker: {
                    show: false,
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `<div class="p-4 rounded-2xl">
                    <span class="text-green-400 font-bold"> h :${series[seriesIndex][dataPointIndex]} cm </span>
                      
                      <br>
                      <span class="mt-2 text-blue-400 font-bold">Elevasi Muka Air : ${w.config.series[seriesIndex].data[dataPointIndex].h ? w.config.series[seriesIndex].data[dataPointIndex].h : ''} m</span>
                    
                      <br>
                      <span class="mt-2 text-yellow-400 font-bold"> Q :${w.config.series[seriesIndex].data[dataPointIndex].debit ? w.config.series[seriesIndex].data[dataPointIndex].debit : ''} m3/dt </span>
                      </div>`
                },
                x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                // lineCap: 'round',
                width: 2,
                dashArray: 0,          
            }
        }
    }),
}