<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-6 border border-gray-200" v-for="item in device" :key="item.id">
            <div class="py-2 px-4 rounded rounded-b-none bg-blue-400 text-white" v-if="item.type == 'level'">Rata-rata ketingian air {{item.name}} minggu ini</div>
            <div class="py-2 px-4 rounded rounded-b-none bg-green-400 text-white" v-else-if="item.type == 'WQ'">Rata-rata pH air {{item.name}} minggu ini</div>
            <div :id="item.code" class="chart-real"></div>
        </div>
     
    </div>
</template>
<script>
var chart = []
import ApexCharts from 'apexcharts'
import { mapGetters } from "vuex"
import globalMixin from '../../mixin/global'
export default {
    mixins:[globalMixin],

    data: () => ({
        update_data:false,
        device:[],
        chart_device:[],
        optionsBar:{
            series: [],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[2001, 2002, 2003, 2004, 2005, 2006, 2007],
            chart: {
                type: 'bar',
                // stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            colors: ['#60A5FA'],

            tooltip: {
                // x: {
                //     format: 'y-MM-dd HH:mm:ss',
                // },
                 y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        let satuan = 'cm'
                      return value + ' ' + satuan
                    }
                  }
            },
            stroke: {
                show: true,
                curve: 'stepline',
                lineCap: 'square',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
    methods:{
        async getData() {
            chart = []
            let customer_id = this.customer ? this.customer.id : ''
            document.querySelectorAll(".chart-real").innerHTML = ''
            await this.axios.get('v2/dashboard/average?customer_id=' + customer_id,this.config)
            .then(async (ress) => {
                this.device = ress.data.data
                await this.generateChart()
                this.chart_device.forEach(async (x,index) => {
                        chart.push(await new ApexCharts(document.querySelector("#" + x.code), x.option))
                 
                })

            })
                chart.forEach(x => {
                x.render()
            })
           
            this.update_data = true
           
        },
        async generateChart() {
            let device = []
            await this.device.forEach(async x => {
                this.$set(this.optionsBar,'labels',x.tgl)
                if (x.type == 'level') {
                    this.$set(this.optionsBar,'series',[{name:'Ketinggian Air [cm]',data:x.real_ketinggian}])
                    
                } else if(x.type == 'WQ') {
                    this.$set(this.optionsBar,'series',[{name:'pH Air',data:x.pH}])
                    this.$set(this.optionsBar,'tooltip',{})
                }
                let option = {
                   ...this.optionsBar
                }
              
                await this.chart_device.push({
                    code : x.code,
                    option : option
                })
            })
        }
    },
    async created() {
        await this.getData()

    },
    computed:{
          ...mapGetters({
            customer:'device/customer',
        })
    },
}
</script>