<template>
  <div class="h-screen w-screen flex flex-wrap content-center justify-center ">
    <div class="w-full h-full">
        <div class="mx-auto rounded grid grid grid-cols-12 items-center">
          <div class="col-span-6 h-screen relative bg-login hidden md:block">
            <img src="/img/Logo_NADA_for_Login.svg" class="absolute top-20 left-24 h-12" alt="">
            <div class="absolute top-52 left-24">
              <span class="text-3xl text-white font-semibold mb-4">Real-Time Dashboard</span>
             
              <p class="mt-4 text-white">Solusi sistem berkualitas tinggi untuk pengamatan yang akurat, terintegrasi dan mudah digunakan terhadap lingkungan.</p>
            </div>
              <!-- <img src="/img/login_nada.svg" class="" /> -->
          </div>
          <div class="col-span-12 md:col-span-6 text-xs">
            <div class="text-left p-24">
                  <div class="mb-8">
                      <span class="text-sm text-gray-400 mb-2">Selamat Datang !</span>
                      <p class="font-bold text-2xl">Masuk ke akun anda</p>

                  </div>
               

                <div v-if="error" class="bg-red-50 border-red-500 text-red-700 text-red-500 p-4 mb-4 mt-4">
                  {{error}}
                </div>
                <ValidationObserver v-slot="{ invalid}" ref="form" >
                <form @submit.prevent="login()" >

                <ValidationProvider rules="required" v-slot="{ errors }" name="Email" vid="email">
                  <div :class="`${errors[0] ? '' : ' mb-4'}`">
                      <defaultInput v-model="email" :field="email" placeholder="Email Atau Username" type="text" />
                      <div class="mb-4">
                          <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                      </div>
                  </div>
                  
                 
                </ValidationProvider>
                <ValidationProvider rules="" v-slot="{ errors }" name="Password" vid="password">
                      <iconInput v-model="password" :field="password" placeholder="Password" type="password" />
                       <div class="mb-4">
                          <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                      </div>
                    
                </ValidationProvider>
                <div class="flex justify-between  items-center ">
                    <div class="flex">
                      <input type="checkbox"  style="border-color:none !important;box-shadow:none !important" class="border border-gray-200 mr-4"> 
                      <span class="font-bold">Ingat Saya</span> 
                    </div>
                    <p :class="`text-center font-bold rounded
                    text-blue-500
                    cursor-pointer`" @click="$router.push('/forgot-password')">Lupa Password ?</p>
                </div>

                <button
                    :disabled="invalid"
                    type="submit"
                    :class="`w-full text-center py-4 mt-8 rounded-lg
                    text-white
                    bg-blue-500
                    disabled:opacity-50
                    focus:outline-none my-1`"


                >Masuk</button>


                </form>

                <!-- <div class="flex justify-between">
                    <div class="flex">
                      <input type="checkbox" class="border-0" name="" id=""> Ingat Saya
                    </div>
                    <p :class="`text-center py-2 font-bold rounded
                    text-blue-500
                    cursor-pointer`" @click="$router.push('/forgot-password')">Lupa Password ?</p>
                </div> -->
             
                </ValidationObserver>
            </div>
               
          </div>
           
        </div>
    </div>
  </div>
</template>
<style scoped>
  .bg-login{
     background-repeat: no-repeat;
     background-image: url("/img/bg-login.png") !important;
       background-size: cover;
  }
</style>
<script>
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import {mapActions} from 'vuex'
import store from '../../store/index.js'
import defaultInput from '../../components/formcomponent/defaultinput.vue'
import iconInput from '../../components/formcomponent/iconinput.vue'
export default {
    data() {
        return {
            email:'',
            password:'',
            error:'',
        }
    },
    components:{
        ValidationProvider,ValidationObserver,defaultInput,iconInput
    },
    methods: {
      ...mapActions({
        setUser : 'auth/setUser'
      }),
      async login() {
        console.log(this.password)
        this.error = ''
        await this.axios.post('v1/auth/login',{email:this.email,password:this.password})
        .then(async (ress) => {
          console.log(ress.data)
          await this.setUser(ress.data)
          await localStorage.setItem('user',JSON.stringify(ress.data.user))
          await localStorage.setItem('token',ress.data.token)
          this.$router.push('/dashboard')

        }).catch((err) => {
          if(err.response.data) {
            this.error = err.response.data.message
          } else {
           this.error = 'Email atau Password salah'

          }
        })
      }
    },

    async beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    let token = localStorage.getItem('token')

    if (token) {
      
      await axios.get('v1/auth/me',{
        headers:{
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(async (ress) => {
          const new_user = ress.data
          await store.dispatch('auth/setUser', new_user)
          await localStorage.setItem('user',JSON.stringify(ress.data.user))
          await localStorage.setItem('token',ress.data.token)
          if(store.getters['beforeUrl']) {
          next(store.getters['beforeUrl']) 

          } else {
          next('/dashboard')

          }
        }
        )
        .catch((err) => {
          localStorage.removeItem('user')
          localStorage.removeItem('token')
          next()
        })
    }
    next()
  }

}
</script>
