<template>
     <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="p-6 text-white font-bold">
            <div class="mb-2">
                <p class="text-sm text-black">Ketinggian Air</p>
                <text-level :item="currentDevice" />
            </div>
            <div v-if="currentDevice.status_siaga == 1">
                <div class="h-20 w-full rounded-t-2xl relative bg-red-500" >
                    <span class="absolute bottom-0 left-4">{{(currentDevice.siaga_1 * 100).toFixed(0)}}</span> 
                </div>
                <div class="h-16 w-full relative bg-yellow-500" >
                    <span class="absolute bottom-0 left-4">{{(currentDevice.siaga_2 * 100).toFixed(0)}}</span> 
                </div>
                <div class="h-12 w-full relative bg-blue-500" >
                    <span class="absolute bottom-0 left-4">{{(currentDevice.siaga_3 * 100).toFixed(0)}}</span> 

                </div>
                <div class="h-20 w-full rounded-b-2xl relative bg-green-500" >
                    <span class="absolute bottom-0 left-4">{{(currentDevice.siaga_4 * 100).toFixed(0)}}</span> 

                </div>
            </div>

            <div v-else style="height:17rem !important" class=" w-full rounded-t-2xl rounded-b-2xl bg-gray-100">

            </div>
            
        </div>

        <div class="absolute bottom-0  w-full" v-if="currentDevice.status_siaga == 1">
            <img src="/img/Wave.svg" class="w-full opacity-75" :class="currentDevice.siaga_level == 4 ? 'rounded-b-2xl' : ''" alt="">
            <div class="bg-blue-400 w-full h-16 rounded-b-2xl opacity-75" v-if="currentDevice.siaga_level == 3"></div>
            <div class="bg-blue-400 w-full h-24 rounded-b-2xl opacity-75" v-else-if="currentDevice.siaga_level == 2"></div>
            <div class="bg-blue-400 w-full h-44 rounded-b-2xl opacity-75" v-else-if="currentDevice.siaga_level == 1"></div>
            <div class="bg-blue-400 w-full" v-else></div>
        </div>

        <div class="absolute bottom-0 w-full" v-else>
            <img src="/img/Wave.svg" class="w-full opacity-75" >
            <div class="bg-blue-400 w-full h-24 rounded-b-2xl opacity-75">
                
            </div>
        </div>
    </div>
</template>

<script>
import textLevel from './textLevel.vue'
export default {
    props:['currentDevice'],
    components:{
        textLevel
    },
}
</script>